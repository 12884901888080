import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import logo from "../../assets/Group12.png";
import input_icon from "../../assets/Group 6650.svg";
import { HashLink } from "react-router-hash-link";
import LockIcon from "@material-ui/icons/LockRounded";

function Footern(props) {
  return (
    <>
      <h3 className="dasdas">hello world</h3>
      <h3 className="dasdas">hello world</h3>
      <h3 className="dasdas">hello world</h3>
      <h3 className="dasdas">hello world</h3>
    </>
  );
}

export default Footern;
